import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import gsap from "gsap"
import { useInView } from "react-intersection-observer"

import Container from "./Layout/Container"
import GridItem from "./GridItem"
import Title from "./Title"

const Wrapper = styled.div`
  padding: ${props => props.theme.sizes.mobile} 0;

  ${props => props.theme.above.desktop`
        padding: calc(${props.theme.sizes.desktop} * 5) 0 calc(${props.theme.sizes.desktop} * 10) 0;
    `}
`

const Header = styled.div`
  display: flex;

  width: 100%;

  ${props => props.theme.below.desktop`
        flex-direction: column;
        align-items: flex-start;

        margin-bottom: ${props => props.theme.mobileVW(20)};
    `}

  ${props => props.theme.above.desktop`
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        margin-bottom: ${props.theme.desktopVW(80)};
    `}
`

const Filter = styled.div`
  /* display: none; */

  /* overflow: hidden; */

  ${props => props.theme.above.desktop`
        display: block;
    `}
`

const FilterInner = styled.div`
  ${props => props.theme.below.desktop`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    `}
`

const FilterItem = styled.button`
  display: inline-block;
  vertical-align: middle;

  margin-right: ${props => props.theme.desktopVW(30)};

  font-size: 1.2rem;

  color: ${props => props.theme.colors.white};

  cursor: pointer;

  transition: opacity 0.25s ease-out;

  ${props =>
    props.active
      ? `
        opacity: 1;
    `
      : `
        opacity: 0.25;

        &:hover {
            opacity: 0.5;
        }
    `}

  ${props => props.theme.below.desktop`
        margin-right: 0;
        margin-bottom: ${props => props.theme.mobileVW(15)};
    `}
`

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${props => props.theme.sizes.mobile};

  ${props => props.theme.above.desktop`
        grid-template-columns: repeat(${props => props.rowCount}, 1fr);
        grid-gap: ${props.theme.desktopVW(120)} ${props.theme.desktopVW(80)};
    `}
`

const StyledTitle = styled(Title)`
  .title-wrapper {
    margin-left: 0;
  }

  h4 {
    margin-bottom: ${props => props.theme.sizes.mobile} / 1.5;

    font-family: ${props => props.theme.fontFamilies.plainLight};
    font-size: ${props => props.theme.fontSizes.mobile.p};
    line-height: 1.3;
  }

  ${props => props.theme.above.desktop`
        h4 {
            margin-bottom: calc(${props.theme.sizes.desktop} / 1.5);

            font-size: ${props.theme.fontSizes.desktop.h6};
        }
    `}
`

const Grid = ({
  lang,
  data: { items, category, gridCategory, sectionTitle },
}) => {
  // States
  const [activeNewsItems, setActiveNewsItems] = useState(-1)
  const [filteredItems, setFilteredItems] = useState([])
  const [defaultItems, setDefaultItems] = useState([])

  // Refs
  const itemRefs = useRef([])
  const titleRef = useRef(null)
  const filterRef = useRef(null)

  const [gridRef, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const {
    eventsAndTripsItems,
    archivedItems,
    blogItems,
    trainerItems,
    careerItems,
    testimonialItems,
  } = useStaticQuery(graphql`
    {
      eventsAndTripsItems: allContentfulArticle(
        filter: { category: { in: ["Events", "Trips"] } }
        sort: { order: DESC, fields: createdAt }
      ) {
        group(field: category) {
          field
          fieldValue
          nodes {
            slug
            category
            name
            title
            excerpt {
              json
            }
            buttonLabel
            featuredImage {
              title
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        default: nodes {
          slug
          category
          name
          title
          excerpt {
            json
          }
          buttonLabel
          featuredImage {
            title
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      archivedItems: allContentfulArticle(
        filter: { category: { eq: "Archived" } }
        sort: { order: ASC, fields: slug }
      ) {
        nodes {
          slug
          category
          name
          title
          excerpt {
            json
          }
          buttonLabel
          featuredImage {
            title
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      blogItems: allContentfulArticle(
        filter: { category: { in: ["News", "Recipes", "Knowledge"] } }
        sort: { order: DESC, fields: createdAt }
      ) {
        group(field: category) {
          field
          fieldValue
          nodes {
            slug
            category
            name
            title
            featuredImage {
              title
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        default: nodes {
          slug
          category
          name
          title
          featuredImage {
            title
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      trainerItems: allContentfulPage(
        filter: { category: { eq: "Trainers" } }
        sort: { order: ASC, fields: slug }
      ) {
        nodes {
          name
          title: name
          category
          slug
          hidden
          excerpt {
            json
          }
          featuredImage {
            title
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      careerItems: allContentfulArticle(
        filter: { category: { eq: "Careers" } }
        sort: { order: DESC, fields: createdAt }
      ) {
        nodes {
          slug
          category
          name
          title
          excerpt {
            json
          }
          featuredImage {
            title
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      testimonialItems: allContentfulTestimonial(
        filter: { showOnBootcampPage: { eq: true } }
        sort: { order: DESC, fields: createdAt }
      ) {
        nodes {
          title
          name
          showOnBootcampPage
          image {
            title
            fluid(maxWidth: 60, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            description
          }
          video {
            videoUrl
            videoSubtitle
            placeholder {
              title
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  let categoryItems
  let showFilter = false
  let showArchived = false

  if (category == "Events and Trips") {
    categoryItems = eventsAndTripsItems
    showFilter = true
    showArchived = true
  } else if (category == "Blog") {
    categoryItems = blogItems
    showFilter = true
  }

  useEffect(() => {
    if (category == "Careers") {
      setFilteredItems(careerItems.nodes)
    } else if (category == "Trainers") {
      setFilteredItems(trainerItems.nodes)
    } else if (category == "Testimonials") {
      setFilteredItems(testimonialItems.nodes)
    }
  }, [])

  useEffect(() => {
    if (
      category == "Careers" ||
      category == "Trainers" ||
      category == "Testimonials"
    )
      return
    if (activeNewsItems == 100) return

    if (activeNewsItems == -1) {
      let items = []

      categoryItems.group.forEach(group => {
        group.nodes.forEach(node => items.push(node))
      })

      setFilteredItems(categoryItems.default)
    } else {
      setFilteredItems(categoryItems.group[activeNewsItems].nodes)
    }
  }, [activeNewsItems])

  useEffect(() => {
    if (activeNewsItems == -1 && !inView) {
      if (filterRef.current)
        gsap.set(filterRef.current, { x: -25.0, alpha: 0.0 })
      itemRefs.current.forEach(item => {
        gsap.set(item, { y: 25.0, alpha: 0.0 })
      })
    } else {
      itemRefs.current.forEach((item, i) => {
        if (item)
          gsap.fromTo(
            item,
            { y: 25.0, alpha: 0.0 },
            {
              y: 0.0,
              alpha: 1.0,
              delay: i * 0.25,
              duration: 0.5,
              ease: "sine.out",
            }
          )
      })
    }
  }, [filteredItems])

  useEffect(() => {
    if (!inView) return

    const timeline = new gsap.timeline({ delay: 0.35 })
    timeline.add(titleRef.current.transitionIn(), 0)

    if (filterRef.current)
      gsap.to(filterRef.current, {
        x: 0.0,
        alpha: 1.0,
        transformOrigin: "left",
        ease: "power3.out",
      })
    itemRefs.current.forEach((item, i) => {
      if (item)
        gsap.fromTo(
          item,
          { y: 25.0, alpha: 0.0 },
          {
            y: 0.0,
            alpha: 1.0,
            delay: i * 0.25,
            duration: 0.5,
            ease: "sine.out",
          }
        )
    })
  }, [inView])

  return (
    <Wrapper
      id={category
        .split(" ")
        .join("-")
        .toLowerCase()}
    >
      <Container ref={gridRef}>
        <StyledTitle
          ref={titleRef}
          lang={lang}
          size="normal"
          title={sectionTitle}
          category={category}
        />
        <Header>
          {showFilter && (
            <Filter>
              <FilterInner ref={filterRef}>
                <FilterItem
                  onClick={() => setActiveNewsItems(-1)}
                  active={activeNewsItems == -1}
                >
                  Show all ({categoryItems.default.length})
                </FilterItem>
                {categoryItems.group.map((category, i) => {
                  return (
                    <FilterItem
                      key={i}
                      active={activeNewsItems == i}
                      onClick={() => setActiveNewsItems(i)}
                    >
                      {category.fieldValue}
                    </FilterItem>
                  )
                })}
              </FilterInner>
            </Filter>
          )}
          {showArchived && (
            <FilterItem
              active={activeNewsItems == 100}
              onClick={() => {
                setFilteredItems(archivedItems.nodes)
                setActiveNewsItems(100)
              }}
              style={{ marginRight: 0 }}
            >
              Archived ({archivedItems.nodes.length})
            </FilterItem>
          )}
        </Header>
        <List rowCount={category == "Testimonials" ? 4 : 3}>
          {filteredItems.map((item, i) => {
            return (
              <GridItem
                key={i}
                lang={lang}
                data={item}
                gridCategory={category}
                ref={el => (itemRefs.current[i] = el)}
              />
            )
          })}
        </List>
      </Container>
    </Wrapper>
  )
}

export default Grid
