import React, { useRef, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import gsap from "gsap"

import Container from "./Layout/Container"
import CalendarItem from "./CalendarItem"
import Title from "./Title"

const Wrapper = styled.section`
  padding: ${props => props.theme.sizes.mobile} 0;

  ${props => props.theme.above.desktop`
        padding: calc(${props.theme.sizes.desktop} * 5) 0 calc(${props.theme.sizes.desktop} * 10) 0;
    `}
`

const Header = styled(Container)`
  margin-bottom: calc(${props => props.theme.sizes.mobile} / 3);

  ${props => props.theme.above.desktop`
        margin-bottom: calc(${props.theme.sizes.desktop} * 5);
    `}
`

const StyledTitle = styled(Title)`
  .title-wrapper {
    margin-left: 0;
  }
`

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${props => props.theme.sizes.mobile};

  ${props => props.theme.above.desktop`
        grid-template-columns: repeat(4, 1fr);
        grid-gap: ${props.theme.desktopVW(120)} ${props.theme.desktopVW(80)};
    `}
`

const Calendar = ({ lang, data }) => {
  // State
  const [calenderItems, setCalenderItems] = useState([])

  // Refs
  const titleRef = useRef(null)
  const itemRefs = useRef([])

  const [calendarRef, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const {
    allItems,
    deepdiveItems,
    rememberingTheDeepItems,
    livingInTheDeepItems,
    bootcampItems,
  } = useStaticQuery(graphql`
    {
      allItems: allContentfulProgram(sort: { order: DESC, fields: startdate }) {
        nodes {
          ...ProgramQuery
        }
      }
      deepdiveItems: allContentfulProgram(
        filter: { type: { in: "Deepdive" } }
        sort: { order: ASC, fields: startdate }
      ) {
        nodes {
          ...ProgramQuery
        }
      }
      bootcampItems: allContentfulProgram(
        filter: { type: { in: "Bootcamp" } }
        sort: { order: ASC, fields: startdate }
      ) {
        nodes {
          ...ProgramQuery
        }
      }
      rememberingTheDeepItems: allContentfulProgram(
        filter: { type: { in: "Remembering the deep" } }
        sort: { order: DESC, fields: startdate }
      ) {
        nodes {
          ...ProgramQuery
        }
      }
      livingInTheDeepItems: allContentfulProgram(
        filter: { type: { in: "Living in the deep" } }
        sort: { order: DESC, fields: startdate }
      ) {
        nodes {
          ...ProgramQuery
        }
      }
    }
  `)

  useEffect(() => {
    const items = {
      All: allItems,
      Deepdive: deepdiveItems,
      "Remebering the deep": rememberingTheDeepItems,
      "Living in the deep": livingInTheDeepItems,
      Bootcamp: bootcampItems,
    }

    let allCalendarItems = items[data.type]

    setCalenderItems(allCalendarItems)
  }, [])

  useEffect(() => {
    // itemsRef.current.forEach((item, i) => {
    //     gsap.set(item, { alpha: 0.0 }, i * 0.15)
    // })

    if (!inView) return

    const timeline = new gsap.timeline()

    timeline.add(titleRef.current.transitionIn(), 0.0)

    return () => {
      timeline && timeline.kill()
    }
  }, [inView])

  return (
    <Wrapper name="calendar" ref={calendarRef}>
      <Header>
        <StyledTitle ref={titleRef} title={data.title} size="normal" />
      </Header>
      <Container>
        <Grid>
          {calenderItems.nodes &&
            calenderItems.nodes.map((item, i) => {
              if (data.type == "Bootcamp") {
                let dateToCheck = new Date(item.startdateRaw)
                let currentDate = new Date()

                if (dateToCheck < currentDate) {
                  return null
                }
              }
              return (
                <CalendarItem
                  key={i}
                  lang={lang}
                  data={item}
                  ref={el => (itemRefs.current[i] = el)}
                />
              )
            })}
        </Grid>
      </Container>
    </Wrapper>
  )
}

export default Calendar
