import React, { useRef, forwardRef, useImperativeHandle } from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { Link, graphql, useStaticQuery } from "gatsby"

import ButtonArrow from "./Buttons/ButtonArrow"
import Tags from "./Tags"

import { generatePath } from "./../utils/helpers"
import TextRenderer from "./TextRenderer"
import Video from "./Video"
import Testimonial from "./Testimonial"

const StyledItem = styled.li`
  overflow: hidden;

  ${props =>
    props.disabled
      ? `
        pointer-events: none;
    `
      : `
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    `}
`

const ImageWrapper = styled(Link)`
  display: block;

  position: relative;

  margin-bottom: calc(${props => props.theme.sizes.mobile} / 1.5);
  padding-bottom: 116.66%;

  ${props => props.theme.above.desktop`
        margin-bottom: ${props.theme.sizes.desktop};
    `}
`

const StyledImage = styled(Image)`
  ${props => props.theme.styles.element.fill};

  position: absolute !important;

  img {
    transition: transform 5s ease-out !important;
  }
`

const Header = styled.div`
  position: relative;

  margin-bottom: calc(${props => props.theme.sizes.mobile} / 1.5);

  ${props => props.theme.above.desktop`
        margin-bottom: calc(${props.theme.sizes.desktop} / 2);
    `}
`

const Heading = styled(Link)`
  display: block;

  font-family: ${props => props.theme.fontFamilies.plainLight};
  font-size: ${props => props.theme.fontSizes.mobile.h5};
  line-height: 1.15;

  ${props => props.theme.above.desktop`
        font-size: ${props.theme.fontSizes.desktop.h5};
    `}
`

const HeadingStatic = styled.span`
  display: block;

  font-family: ${props => props.theme.fontFamilies.plainLight};
  font-size: ${props => props.theme.fontSizes.mobile.m};
  line-height: 1.15;

  ${props => props.theme.above.desktop`
        font-size: ${props.theme.fontSizes.desktop.h6};
    `}
`

const HeadingSmall = styled.span`
  display: block;

  font-family: ${props => props.theme.fontFamilies.plainLight};
  font-size: ${props => props.theme.fontSizes.mobile.s};
  line-height: 1.43;

  ${props => props.theme.above.desktop`
        font-size: ${props.theme.fontSizes.desktop.m};

        margin-bottom: calc(${props.theme.sizes.desktop} / 6);
    `}
`

const NameStatic = styled.h4`
  display: block;

  margin-top: 0.5rem;

  font-family: ${props => props.theme.fontFamilies.plainLight};
  font-size: ${props => props.theme.fontSizes.mobile.s};
  line-height: 1.15;

  color: rgba(255, 255, 255, 0.5);

  ${props => props.theme.above.desktop`
      font-size: ${props.theme.fontSizes.desktop.s};
  `}
`

const StyledTags = styled(Tags)`
  margin-top: calc(${props => props.theme.sizes.mobile} / 1.5);

  ${props => props.theme.above.desktop`
        margin-top: calc(${props.theme.sizes.desktop} / 1.5);
    `}
`

const Description = styled(TextRenderer)`
  margin-bottom: calc(${props => props.theme.sizes.mobile} / 1.5) !important;

  font-size: ${props => props.theme.fontSizes.mobile.m};

  ${props => props.theme.above.desktop`
        margin-bottom: calc(${props.theme.sizes.desktop} / 1.5) !important;

        font-size: ${props.theme.fontSizes.desktop.p};
    `}
`

const Footer = styled.div`
  ${props => props.theme.styles.flexBox.horCen};
`

const Category = styled.span`
  display: block;

  margin-bottom: calc(${props => props.theme.sizes.mobile} / 4);

  opacity: 0.5;

  font-family: ${props => props.theme.fontFamilies.nbRegular};
  font-size: ${props => props.theme.fontSizes.mobile.s};
  line-height: 1.5;

  text-transform: uppercase;

  ${props => props.theme.above.desktop`
        margin-bottom: calc(${props.theme.sizes.desktop} / 3);

        font-size: ${props.theme.fontSizes.desktop.m};
    `}
`

const Fallback = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
`

const StyledLogo = styled.img`
  width: ${props => props.theme.mobileVW(50)};

  ${props => props.theme.above.desktop`
        width: ${props => props.theme.desktopVW(75)};
    `}
`

const VideoWrapper = styled.div`
  margin-bottom: 1rem;
`

const StyledTestimonial = styled(Testimonial)`
  margin-top: 1rem;
`

const TestimonialVideo = styled(Video)`
  .video-controls {
    top: 0;
    left: 0;
    bottom: initial;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .video-play {
    transform: scale(1.5);

    rect,
    polygon {
      fill: #fff !important;
    }
  }

  ${props => props.theme.above.desktop`
    aspect-ratio: 9/6;
  `}
`

const GridItem = ({ lang, gridCategory, data }, ref) => {
  const {
    title,
    slug,
    hidden,
    category,
    featuredImage,
    buttonLabel,
    excerpt,
    components,
    video,
    image,
    name,
    description,
  } = data

  // Refs
  const buttonRef = useRef(null)

  let link
  let showDescription = false
  let showCategory = false

  const { logoImage } = useStaticQuery(graphql`
    {
      logoImage: allFile(filter: { relativePath: { eq: "rl.png" } }) {
        nodes {
          publicURL
        }
      }
    }
  `)

  if (gridCategory == "Events and Trips") {
    link = `events-and-trips/${category.toLowerCase()}/${slug}`
    showCategory = true
    showDescription = true
  } else if (gridCategory == "Blog") {
    link = `blog/${category.toLowerCase()}/${slug}`
    showCategory = true
  } else if (gridCategory == "Trainers") {
    link = `physical-training/${category.toLowerCase()}/${slug}`
    showDescription = true
  } else if (gridCategory == "Testimonials") {
    link = false

    let data = {
      image: image,
      name: name,
      description: {
        description: description.description,
      },
    }

    return (
      <StyledItem>
        <VideoWrapper>
          <TestimonialVideo
            url={video.videoUrl}
            inView={true}
            placeholder={video.placeholder}
          />
        </VideoWrapper>
        <HeadingStatic>{title}</HeadingStatic>
        <StyledTestimonial data={data} hideQuotes={true} />
      </StyledItem>
    )
  } else {
    link = `${category.toLowerCase()}/${slug}`
  }

  return (
    <StyledItem
      ref={ref}
      onMouseEnter={() => buttonRef.current.classList.add("hover")}
      onMouseLeave={() => buttonRef.current.classList.remove("hover")}
      disabled={hidden}
    >
      <ImageWrapper to={generatePath(lang, link)}>
        {featuredImage?.fluid ? (
          <StyledImage fluid={featuredImage.fluid} alt={featuredImage.title} />
        ) : (
          <Fallback>
            <StyledLogo
              src={logoImage.nodes[0].publicURL}
              alt="Rockstar Lifestyle - Short logo"
            />
          </Fallback>
        )}
      </ImageWrapper>
      <Header>
        {showCategory && <Category>{category}</Category>}
        {link ? (
          <Heading to={generatePath(lang, link)}>{title}</Heading>
        ) : (
          <HeadingStatic>{title}</HeadingStatic>
        )}
        {gridCategory == "Careers" && components && components[0].tags && (
          <StyledTags data={components[0].tags} slice={1} />
        )}
      </Header>
      {showDescription && excerpt && <Description data={excerpt} />}
      {!hidden && (
        <Footer>
          <ButtonArrow
            ref={buttonRef}
            label={buttonLabel || "Read more"}
            to={generatePath(lang, link)}
          />
        </Footer>
      )}
    </StyledItem>
  )
}

export default forwardRef(GridItem)
