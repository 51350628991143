import React, { useRef, forwardRef } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { useWindowSize } from 'react-use'

import ButtonArrow from './Buttons/ButtonArrow'
import Tags from './Tags'
import TextRenderer from './TextRenderer'

import { generatePath } from './../utils/helpers'

const StyledItem = styled.li`
    ${props => props.disabled ? `
        pointer-events: none;
    ` : `
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    `}
`

const ImageWrapper = styled.div`
    display: block;
    
    position: relative;

    margin-bottom: calc(${props => props.theme.sizes.mobile} / 1.5);
    padding-bottom: 116.66%;

    ${props => props.theme.above.desktop`
        margin-bottom: ${props.theme.sizes.desktop};
    `}
`

const StyledImage = styled(Image)`
    ${props => props.theme.styles.element.fill};

    position: absolute !important;

    img {
        transition: transform 5.0s ease-out !important;
    }
`

const Header = styled.div`
    position: relative;

    margin-bottom: calc(${props => props.theme.sizes.mobile} / 1.5);

    ${props => props.theme.above.desktop`
        margin-bottom: calc(${props.theme.sizes.desktop} / 2);
    `}
`

const Heading = styled.h4`
    display: block;

    font-family: ${props => props.theme.fontFamilies.plainLight};
    font-size: ${props => props.theme.fontSizes.mobile.h5};
    line-height: 1.3;

    ${props => props.theme.above.desktop`
        font-size: ${props.theme.fontSizes.desktop.h5};
    `}
`

const Description = styled(TextRenderer)`
    margin-bottom: calc(${props => props.theme.sizes.mobile} / 1.5) !important;
    
    font-size: ${props => props.theme.fontSizes.mobile.m};

    ${props => props.theme.above.desktop`
        margin-bottom: calc(${props.theme.sizes.desktop} / 1.5) !important;

        font-size: ${props.theme.fontSizes.desktop.p};

        li {
            p {
                margin-bottom: 0 !important;
            }
        }
    `}
`

const Footer = styled.div`
    ${props => props.theme.styles.flexBox.horCen};
`

const Category = styled.span`
    display: block;
    
    margin-bottom: calc(${props => props.theme.sizes.mobile} / 4);

    opacity: 0.5;
    
    font-family: ${props => props.theme.fontFamilies.nbRegular};
    font-size: ${props => props.theme.fontSizes.mobile.s};
    line-height: 1.5;

    text-transform: uppercase;

    ${props => props.theme.above.desktop`
        margin-bottom: calc(${props.theme.sizes.desktop} / 3);

        font-size: ${props.theme.fontSizes.desktop.m};
    `}
`

const StyledTags = styled(Tags)`
    position: absolute;
    
    z-index: 1;

    justify-content: flex-end;
    
    ${props => props.theme.below.desktop`
        display: none;
    `}

    ${props => props.theme.above.desktop`
        .tag {
            margin-bottom: 0;
        }

        bottom: calc(${props.theme.sizes.desktop} / 1.5);
        right: calc(${props.theme.sizes.desktop});
    `}
`

const CalendarItem = ({ 
    lang,
    data
}, ref) => {

    const { width: windowWidth } = useWindowSize()

    const { 
        title,
        type,
        thumbnail,
        excerpt,
        startdate,
        enddate,
        year,
        startDateFormInput,
        endDateFormInput,
        location
    } = data
    
    // Refs
    const buttonRef = useRef(null)

    let tagItems = []
    tagItems.push(type)
    tagItems.push(year)

    return (
        <StyledItem ref={ref}>
            {/* <ImageWrapper>
                <StyledImage fluid={thumbnail.fluid} alt={thumbnail.title} />
                <StyledTags data={tagItems}></StyledTags>
            </ImageWrapper> */}
            <Header>
                <Category>{`${startdate.replace('.', '')} - ${enddate.replace('.', '')}`} / <span>{location}</span></Category>
                <Heading>{title}</Heading>
            </Header>
            <Description data={excerpt} />
            <Footer>
                <ButtonArrow 
                    ref={buttonRef}
                    label={type == 'Bootcamp' ? 'Send your motivation' : 'Reserve your space'}
                    to={generatePath(lang, type == 'Bootcamp' ? 'join-us' : 'reserve-your-space')} 
                    modal={{
                        modal: windowWidth < 1023 ? false : true,
                        formInput: `${type} / ${startdate.replace('.', '')} - ${enddate.replace('.', '')} / ${location}`
                    }}
                />
            </Footer>
        </StyledItem>
    )
}

export default forwardRef(CalendarItem)
